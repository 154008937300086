"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require('select2');
function InitEd() {
    window.addFullWidth = function () {
        if (!$(".content-wrapper").hasClass("has-bg-image")) {
            $(".o-navigation-wrapper").addClass("u-z-999 u-absolute u-full-width");
            $(".content-wrapper").addClass("has-bg-image");
        }
    };
    window.removeFullWidth = function () {
        if ($(".content-wrapper").hasClass("has-bg-image")) {
            $(".o-navigation-wrapper").removeClass("u-z-999 u-absolute u-full-width");
            $(".content-wrapper").removeClass("has-bg-image");
        }
    };
    window.closeLanguage = function () {
        $("#page-wide-language-wrapper").removeClass("display-language-field");
        $("body").removeClass("u-ofh");
        $(".main-language-button i").html("language");
        $(".o-mobile-menu").removeClass("u-fixed");
    };
    window.openLanguage = function () {
        $("#page-wide-language-wrapper").addClass("display-language-field");
        $("body").addClass("u-ofh");
        $(".main-language-button i").html("close");
        $(".o-mobile-menu").addClass("u-fixed");
    };
    $('document').ready(function () {
        $("body").on("click", ".o-scroll-hero .o-round-button", function (e) {
            $("html, body").animate({ scrollTop: $(window).height() });
        });
    });
    //Arrow glyphs are hard-coded to HTML source, so clear html arrows away and introduce Material Icon arrows via CSS
    setTimeout(function () {
        $('.slick-dots.slick-arrow .s-circle.arrow-circle, .o-back-to-top .s-circle.arrow-circle').empty();
        $('.slick-dots.slick-arrow .s-circle.arrow-circle').html('<i class="material-icons">arrow_downward</i>');
        $('.o-back-to-top .s-circle.arrow-circle').html('<i class="material-icons">arrow_upward</i>');
    }, 50);
    //Initialize select2 for ED Contact form
    $('.Form__Element.FormSelection select').hide();
    $('.Form__Element.FormSelection select').select2({
        minimumResultsForSearch: Infinity,
        width: 'resolve',
        dropdownParent: $('.Form__MainBody')
    });
    // anchor links
    jQuery('.c-button-container a, .hero a.c-cta').click(function (e) {
        var linkUrl = jQuery(this).attr('href');
        var hashCharIdx = linkUrl.indexOf('#');
        if (hashCharIdx > -1) {
            e.preventDefault();
            var anchorId = linkUrl.substring(hashCharIdx);
            var anchorElem = jQuery(anchorId);
            var scrollPosition = (anchorElem !== undefined) ? jQuery(anchorElem)[0].offsetTop : 0;
            $("html, body").animate({ scrollTop: scrollPosition });
        }
    });
    //const isMobile = () => {
    //    return (jQuery(window).innerWidth() < 768);
    //}
    //const iOSDevice = () => {
    //    if (/Android|webOS|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    //        // true for iOS device
    //        return true;
    //    } else {
    //        // false for not iOS device
    //        return false;
    //    }
    //};
    var isIOS = function () {
        if (/iPad|iPhone|iPod/.test(navigator.platform)) {
            return true;
        }
        else {
            return navigator.maxTouchPoints &&
                navigator.maxTouchPoints > 2 &&
                /MacIntel/.test(navigator.platform);
        }
    };
    // SPONSORED-HAKULOMAKE
    window.hideWarning = true;
    if (jQuery('form[data-f-metadata=set-prompt]').length) {
        // Set prompt if user is leaving form page before submit
        window.addEventListener('beforeunload', function (event) {
            if (!window.hideWarning) {
                event.preventDefault();
                event.returnValue = '';
            }
        });
        //console.log(navigator.platform);
        if (isIOS()) {
            //console.log('iOS');
            jQuery('.o-mobile-nav a, footer a, .o-navigation-wrapper a, a.o-mobile-logo-link').click(function (e) {
                if (!window.hideWarning) {
                    if (!window.confirm("Haluatko varmasti poistua sivulta?")) {
                        e.preventDefault();
                    }
                }
            });
        }
        // Show prompt after next button is clicked
        jQuery('button.btnNext').on('click', function () {
            window.hideWarning = false;
        });
        // disable prompt after submit
        jQuery('button.FormSubmitButton').on('click', function () {
            setTimeout(function () {
                if (jQuery('form.EPiServerForms.ValidationFail').length === 0) {
                    window.hideWarning = true;
                }
            }, 200);
        });
    }
}
exports.InitEd = InitEd;
